const apiURL = "https://adguide.kr/api";

module.exports = {
    apiURL: apiURL,
    wrapFetch: (url, type, data) => {
        return new Promise((res, rej) => {
            fetch(apiURL + url, {
                method: type,
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": 'application/json'
                },
                credentials: "include",
            }).then((response) => response.json())
            .then(responseData => {
                if(responseData.status == 403){
                    alert("계정이 만료되어 이용할 수 없는 기능입니다.");
                    location.href = "/";
                }else if(responseData.resultCode == "Unauthorized"){
                    // alert(responseData.resultMessage || "로그인이 만료되었습니다. 재 로그인해주시기 바랍니다.");
                    sessionStorage.setItem("loginInfo", null);
                    location.href = "/login";
                }else if(responseData.resultCode !== "OK"){
                    alert(responseData.resultMessage || "처리에 오류가 발생하였습니다. \n문제가 지속될 경우 관리자에게 문의 부탁드립니다.");
                    rej(responseData);
                }else{
                    res(responseData);
                }
            })
        })
    }
}